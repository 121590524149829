import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClientsCarsApi } from 'src/api';
import ClientCarForm from 'src/components/clients/cars/form';
import { IClientCarForm } from 'src/types/client-car';

const CreateClientCar: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { clientId } = useParams();
  const mutation = useMutation(ClientsCarsApi.create, {
    onSuccess: () => {
      toast.success('Voiture du client ajouté avec succés');
      queryClient.invalidateQueries(['clients_cars', clientId]).catch(() => { });
      navigate(`/app/clients/${clientId}`);
    },
    onError: () => {
      toast.error("une erreur est survenue lors de l'ajout");
    }
  });

  const onSubmit = (data: Partial<IClientCarForm>) => {
    mutation.mutate({ ...data, owner_id: parseInt(clientId) });
  };

  return (
    <>
      <Helmet>
        <title>Création d'une voiture de client | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ p: '2rem' }}>
          <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
            <Typography variant="h2">
              Création d'une voiture de client
            </Typography>
          </Box>
          <Box
            sx={{
              px: '2rem'
            }}
          >
            <ClientCarForm onSubmit={onSubmit} />
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateClientCar;
