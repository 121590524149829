import { IClient } from './client';
import { IClientCar } from './client-car';
import { Id } from './common';
import { IPartner } from './partner';
import { IResponsible } from './responsible';

export enum PaymentModeEnum {
  CASH = 'CASH',
  CHECK = 'CHECK',
  CREDIT_CARD = 'CREDIT_CARD',
  TRANSFER = 'TRANSFER'
}

export enum PaymentStatusEnum {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  PARTLY_PAID = 'PARTLY_PAID',
  OVERDUE = 'OVERDUE',
  NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export enum DurationUnitEnum {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  months = 'months'
}

export type InvoiceType = 'invoice' | 'quote';

export const INVOICE_PAYEMENT_MODES_LABELS = {
  [PaymentModeEnum.CASH]: 'Cash',
  [PaymentModeEnum.CHECK]: 'Chèque',
  [PaymentModeEnum.CREDIT_CARD]: 'Carte de crédit',
  [PaymentModeEnum.TRANSFER]: 'Virement bancaire'
};

export const INVOICE_PAYEMENT_STATUSES_LABELS = {
  [PaymentStatusEnum.PAID]: 'Payé',
  [PaymentStatusEnum.UNPAID]: 'Non payé',
  [PaymentStatusEnum.PARTLY_PAID]: 'Payé en partie',
  [PaymentStatusEnum.OVERDUE]: 'En retard',
  [PaymentStatusEnum.NOT_APPLICABLE]: 'Non applicable'
};

export const INVOICE_DURATION_UNITS_LABELS = {
  [DurationUnitEnum.minutes]: 'Minutes',
  [DurationUnitEnum.hours]: 'Heures',
  [DurationUnitEnum.days]: 'Jours',
  [DurationUnitEnum.months]: 'Mois'
};

export const INVOICE_ENUMS = {
  PAYMENT_MODES: [
    {
      label: INVOICE_PAYEMENT_MODES_LABELS[PaymentModeEnum.CASH],
      value: PaymentModeEnum.CASH
    },
    {
      label: INVOICE_PAYEMENT_MODES_LABELS[PaymentModeEnum.CHECK],
      value: PaymentModeEnum.CHECK
    },
    {
      label: INVOICE_PAYEMENT_MODES_LABELS[PaymentModeEnum.CREDIT_CARD],
      value: PaymentModeEnum.CREDIT_CARD
    },
    {
      label: INVOICE_PAYEMENT_MODES_LABELS[PaymentModeEnum.TRANSFER],
      value: PaymentModeEnum.TRANSFER
    }
  ],
  PAYMENT_STATUSES: [
    {
      label: INVOICE_PAYEMENT_STATUSES_LABELS[PaymentStatusEnum.PAID],
      value: PaymentStatusEnum.PAID
    },
    {
      label: INVOICE_PAYEMENT_STATUSES_LABELS[PaymentStatusEnum.UNPAID],
      value: PaymentStatusEnum.UNPAID
    },
    {
      label: INVOICE_PAYEMENT_STATUSES_LABELS[PaymentStatusEnum.PARTLY_PAID],
      value: PaymentStatusEnum.PARTLY_PAID
    },
    {
      label: INVOICE_PAYEMENT_STATUSES_LABELS[PaymentStatusEnum.OVERDUE],
      value: PaymentStatusEnum.OVERDUE
    }
  ],
  DURATION_UNITS: [
    {
      label: INVOICE_DURATION_UNITS_LABELS[DurationUnitEnum.minutes],
      value: DurationUnitEnum.minutes
    },
    {
      label: INVOICE_DURATION_UNITS_LABELS[DurationUnitEnum.hours],
      value: DurationUnitEnum.hours
    },
    {
      label: INVOICE_DURATION_UNITS_LABELS[DurationUnitEnum.days],
      value: DurationUnitEnum.days
    },
    {
      label: INVOICE_DURATION_UNITS_LABELS[DurationUnitEnum.months],
      value: DurationUnitEnum.months
    }
  ]
};

export interface IInvoice {
  id: Id;
  internal_id: number;
  client_id: number;
  client_car_id: number;
  partner_id: number;
  responsible_id: number;
  client: IClient;
  client_car: IClientCar;
  partner: IPartner;
  responsible: IResponsible;
  payment_mode: PaymentModeEnum;
  payment_status: PaymentStatusEnum;
  comments: string;
  stamp_price: number;
  tva: number;
  hourly_rate: number;
  working_duration: number;
  working_duration_unit: DurationUnitEnum;
  creation_year: number;
  date: Date;
  operations: Array<IInvoiceOperation>;
  pieces: Array<IInvoicePiece>;
  operations_sub_total_before_discount: number;
  operations_sub_total: number;
  pieces_sub_total_before_discount: number;
  pieces_sub_total: number;
  created_at?: Date;
  updated_at?: Date;
}

export interface IInvoiceForm {
  id: Id;
  internal_id?: number;
  client_id: number | null;
  client_car_id: number | null;
  partner_id: number | null;
  responsible_id: number | null;
  client: IClient | null;
  client_car: IClientCar | null;
  partner: IPartner | null;
  responsible: IResponsible | null;
  payment_mode: PaymentModeEnum;
  payment_status: PaymentStatusEnum;
  comments: string;
  stamp_price: number;
  tva: number;
  hourly_rate: number;
  working_duration: number;
  working_duration_unit: DurationUnitEnum;
  date: Date | string;
  operations: Array<IInvoiceOperation>;
  pieces: Array<IInvoicePiece>;
  operations_sub_total_before_discount: number;
  operations_sub_total: number;
  pieces_sub_total_before_discount: number;
  pieces_sub_total: number;
}

export interface IInvoiceOperation {
  operation_id: Id;
  piece_id: Id;
  duration: number;
  hourly_rate: number;
  total_before_discount: number;
  total: number;
  discount: number;
}

export interface IInvoicePiece {
  piece_id: Id;
  reference: string;
  quantity: number;
  unit_price: number;
  total_before_discount: number;
  total: number;
  discount: number;
}
