import { Box, Card, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ClientsCarsApi } from 'src/api';
import ClientCarForm from 'src/components/clients/cars/form';
import { IClientCarForm } from 'src/types/client-car';

const EditClientCar: React.FC = () => {
  const { id, clientId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: clientCar, isLoading } = useQuery(
    ['clients_cars', id],
    () => ClientsCarsApi.get(id),
    {
      onError: () => {
        navigate(`/app/clients/${clientId}`);
        toast.error('une erreur est survenue');
      }
    }
  );

  const mutation = useMutation(ClientsCarsApi.update, {
    onSuccess: () => {
      toast.success('Voiture du client modifié avec succés');
      queryClient.invalidateQueries(['clients_cars', id]).catch(() => { });
      navigate(`/app/clients/${clientId}`);
    },
    onError: () => {
      toast.error('une erreur est survenue lors de la modification');
    }
  });

  const onSubmit = (data: Partial<IClientCarForm>) => {
    mutation.mutate({
      id: id,
      body: { ...data, owner_id: parseInt(clientId) }
    });
  };
  return (
    <>
      <Helmet>
        <title>Édition d'une voiture de client | Pitstop</title>
      </Helmet>
      <Container sx={{ py: '2rem' }}>
        <Card sx={{ py: '2rem' }}>
          {isLoading ? (
            'Loading ...'
          ) : (
            <>
              <Box sx={{ width: '100%', py: '2rem', textAlign: 'center' }}>
                <Typography variant="h2">
                  Édition d'une voiture de client
                </Typography>
              </Box>
              <Box
                sx={{
                  px: '2rem'
                }}
              >
                <ClientCarForm onSubmit={onSubmit} defaultValues={clientCar} />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default EditClientCar;
