import { Button, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useCallback } from 'react';
import { memo } from 'react';
import { useEffect } from 'react';
import {
  FieldArrayMethodProps,
  useFieldArray,
  useWatch
} from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import useDidMountEffect from 'src/hooks/use-did-mount-effect';
import { Id } from 'src/types/common';
import { IOptionItem } from 'src/types/form';
import {
  IInvoiceForm,
  IInvoiceOperation,
  IInvoicePiece
} from 'src/types/invoice';
import { IOperation, OperationTypesEnum } from 'src/types/operation';
import { IPiece } from 'src/types/piece';
import { FormInput } from '../controls';
import FormSelectAutoComplete from '../controls/select-autocomplete';

const PieceSelectorField = ({
  operations = [],
  pieces = [],
  index,
  insert,
  appendPiece,
  defaultValue
}: {
  operations?: IOperation[];
  pieces?: IPiece[];
  index: number;
  appendPiece: (
    value: Partial<IInvoicePiece> | Partial<IInvoicePiece>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  insert: (
    index: number,
    value: Partial<IInvoiceOperation> | Partial<IInvoiceOperation>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  defaultValue?: Id | null;
}) => {
  const methods = useFormContext<IInvoiceForm>();

  const [filteredPieces, setFilteredPieces] = useState<IOptionItem[]>([]);
  const operationId = useWatch({
    name: `operations.${index}.operation_id`,
    control: methods.control
  }) as string;
  const pieceId = useWatch({
    name: `operations.${index}.piece_id`,
    control: methods.control
  }) as string;

  useEffect(() => {
    setFilteredPieces(
      pieces.reduce((result: IOptionItem[], piece) => {
        if (operationId) {
          const operation = operations.find(
            (operation) => operation.id === parseInt(operationId)
          );
          if (operation) {
            if (
              (operation.type === OperationTypesEnum.PAINTING &&
                piece.paintable) ||
              (operation.type === OperationTypesEnum.REPAIR &&
                piece.repairable) ||
              (operation.type === OperationTypesEnum.REMPLACEMENT &&
                piece.replaceable)
            ) {
              result.push({
                label: piece.name,
                value: piece.id
              });
            }
          }
        }
        return result;
      }, [])
    );
  }, [operationId, pieces, operations]);

  useDidMountEffect(() => {
    if (pieceId) {
      const piece = pieces.find((piece) => piece.id === parseInt(pieceId));
      const operation = operations.find(
        (operation) => operation.id === parseInt(operationId)
      );
      const hourlyRate = methods.getValues('hourly_rate');
      if (piece && operation) {
        if (
          operation.type === OperationTypesEnum.PAINTING &&
          piece.paintable_pieces
        ) {
          const applied_pieces = pieces.filter((p) =>
            piece.paintable_pieces?.includes(p.id)
          );

          applied_pieces.forEach((p, i) => {
            insert(index + i + 1, {
              operation_id: operation.id,
              piece_id: p.id,
              duration: operation.volume,
              hourly_rate: operation.price || hourlyRate || 0,
              total: 0
            });
          });
        } else if (
          operation.type === OperationTypesEnum.REMPLACEMENT &&
          piece.replaceable_pieces
        ) {
          const applied_pieces = pieces.filter((p) =>
            piece.replaceable_pieces?.includes(p.id)
          );

          const newOperations: IInvoiceOperation[] = [];
          const newPieces: IInvoicePiece[] = [
            {
              reference: '',
              piece_id: piece.id,
              unit_price: 0,
              quantity: 1,
              total_before_discount: 0,
              total: 0,
              discount: 0
            }
          ];
          applied_pieces.forEach((p) => {
            newOperations.push({
              operation_id: operation.id,
              piece_id: p.id,
              duration: operation.volume,
              hourly_rate: operation.price || hourlyRate || 0,
              total_before_discount: 0,
              total: 0,
              discount: 0
            });
            newPieces.push({
              reference: '',
              piece_id: p.id,
              unit_price: 0,
              quantity: 1,
              total_before_discount: 0,
              total: 0,
              discount: 0
            });
          });
          insert(index + 1, newOperations);
          appendPiece(newPieces);
        } else if (
          operation.type === OperationTypesEnum.REPAIR &&
          piece.repairable_pieces
        ) {
          const applied_pieces = pieces.filter((p) =>
            piece.repairable_pieces?.includes(p.id)
          );

          applied_pieces.forEach((p, i) => {
            insert(index + i + 1, {
              operation_id: operation.id,
              piece_id: p.id,
              duration: operation.volume,
              hourly_rate: operation.price || hourlyRate || 0,
              total: 0
            });
          });
        } else {
          console.log('error');
        }
      }
    }
  }, [pieceId]);

  return (
    <FormSelectAutoComplete
      name={`operations.${index}.piece_id`}
      options={filteredPieces || []}
      label="Piéce"
      defaultValue={defaultValue}
    />
  );
};

const TotalBeforeDiscountField = ({
  index,
  defaultValue
}: {
  index: number;
  defaultValue: number;
}) => {
  const { setValue, watch } = useFormContext();
  const [duration, hourly_rate] = watch([
    `operations.${index}.duration`,
    `operations.${index}.hourly_rate`,
  ]);

  useEffect(() => {
    const total = (duration || 0) * (hourly_rate || 0);
    setValue(`operations.${index}.total_before_discount`, parseFloat(total.toFixed(3)));
  }, [duration, hourly_rate, setValue, index]);

  return (
    <FormInput
      disabled
      name={`operations.${index}.total_before_discount`}
      type="number"
      label="Prix HT Avant Remise"
      defaultValue={defaultValue}
    />
  );
};

const TotalField = ({
  index,
  defaultValue
}: {
  index: number;
  defaultValue: number;
}) => {
  const { setValue, watch } = useFormContext();
  const [duration, hourly_rate, discount] = watch([
    `operations.${index}.duration`,
    `operations.${index}.hourly_rate`,
    `operations.${index}.discount`
  ]);

  useEffect(() => {
    const total = (duration || 0) * (hourly_rate || 0) * (1 - discount / 100);
    setValue(`operations.${index}.total`, parseFloat(total.toFixed(3)));
  }, [duration, hourly_rate, setValue, index, discount]);

  return (
    <FormInput
      disabled
      name={`operations.${index}.total`}
      type="number"
      label="Prix HT"
      defaultValue={defaultValue}
    />
  );
};

const DiscountField = ({
  index,
  defaultValue
}: {
  index: number;
  defaultValue: number;
}) => {
  return (
    <FormInput
      name={`operations.${index}.discount`}
      type="number"
      inputProps={{ min: 0, max: 100 }}
      onWheel={(e) => (e.target as HTMLElement).blur()}
      label="Remise"
      defaultValue={defaultValue}
    />
  );
};

const DurationField = ({
  operations = [],
  index,
  defaultValue
}: {
  operations?: IOperation[];
  index: number;
  defaultValue: number;
}) => {
  const methods = useFormContext();
  const operationId = methods.watch(`operations.${index}.operation_id`);

  useDidMountEffect(() => {
    if (operationId) {
      const operation = operations.find(
        (operation) => operation.id === parseInt(operationId)
      );
      methods.setValue(`operations.${index}.duration`, operation?.volume || 0);
    }
  }, [operationId]);

  return (
    <FormInput
      name={`operations.${index}.duration`}
      type="number"
      onWheel={(e) => (e.target as HTMLElement).blur()}
      label="Durée(H)"
      defaultValue={defaultValue}
    />
  );
};

const HourlyRateField = ({
  operations = [],
  index,
  defaultValue
}: {
  operations: IOperation[];
  index: number;
  defaultValue: number;
}) => {
  const methods = useFormContext();
  const operationId = methods.watch(`operations.${index}.operation_id`);

  useDidMountEffect(() => {
    if (operationId) {
      const operation = operations.find(
        (operation) => operation.id === parseInt(operationId)
      );
      const hourlyRate = parseInt(methods.getValues('hourly_rate'));
      methods.setValue(
        `operations.${index}.hourly_rate`,
        hourlyRate || operation?.price || 0
      );
    }
  }, [operationId]);

  return (
    <FormInput
      name={`operations.${index}.hourly_rate`}
      type="number"
      onWheel={(e) => (e.target as HTMLElement).blur()}
      label="Taux"
      defaultValue={defaultValue}
    />
  );
};

const SubTotalBeforeDiscountField = ({ defaultValue }: { defaultValue: number }) => {
  const methods = useFormContext();
  const operations = methods.watch(`operations`);

  useDidMountEffect(() => {
    const total = (operations as IInvoiceOperation[]).reduce(
      (result, operation) => {
        return result + operation.total_before_discount;
      },
      0
    );
    methods.setValue(`operations_sub_total_before_discount`, total);
  }, [operations]);

  return (
    <FormInput
      disabled
      name={`operations_sub_total_before_discount`}
      type="number"
      label="S. Total M.O HT avant Remise en TND"
      defaultValue={defaultValue}
    />
  );
};

const SubTotalField = ({ defaultValue }: { defaultValue: number }) => {
  const methods = useFormContext();
  const operations = methods.watch(`operations`);

  useDidMountEffect(() => {
    const total = (operations as IInvoiceOperation[]).reduce(
      (result, operation) => {
        return result + operation.total;
      },
      0
    );
    methods.setValue(`operations_sub_total`, total);
  }, [operations]);

  return (
    <FormInput
      disabled
      name={`operations_sub_total`}
      type="number"
      label="S. Total M.O HT en TND"
      defaultValue={defaultValue}
    />
  );
};

type Props = {
  operations: Array<IOperation>;
  pieces: Array<IPiece>;
};
const InvoiceOperations: React.FC<Props> = ({ operations, pieces }) => {
  const methods = useFormContext<IInvoiceForm>();
  const { fields, append, insert, remove } = useFieldArray({
    control: methods.control,
    name: 'operations'
  });

  const {
    fields: piecesFields,
    append: appendPiece,
    remove: removePiece
  } = useFieldArray({
    control: methods.control,
    name: 'pieces'
  });

  const onRemove = useCallback(
    (index: number) => {
      const ioperation = fields[index];
      const operation = operations.find(
        (o) => o.id === ioperation.operation_id
      );
      if (operation && operation.type === OperationTypesEnum.REMPLACEMENT) {
        const piece = pieces.find((p) => p.id === ioperation.piece_id);
        if (piece) {
          const i = piecesFields.findIndex((s) => s.piece_id === piece.id);
          if (i !== -1) {
            removePiece(i);
          }
        }
      }
      remove(index);
    },
    [fields, remove, piecesFields, removePiece, operations, pieces]
  );

  return (
    <Grid container spacing={2}>
      {fields.map((operation, index) => (
        <Grid container item xs={12} key={operation.id} spacing={2}>
          <Grid item xs={1}>
            <Button variant="contained" onClick={() => onRemove(index)}>
              -
            </Button>
          </Grid>
          <Grid container item xs={11} spacing={3}>
            <Grid item xs={6}>
              <FormSelectAutoComplete
                name={`operations.${index}.operation_id`}
                options={
                  operations.map((operation) => {
                    return {
                      label: operation.label,
                      value: operation.id
                    };
                  }) || []
                }
                label="Opération"
                defaultValue={operation.operation_id}
              />
            </Grid>
            <Grid item xs={6}>
              <PieceSelectorField
                operations={operations}
                pieces={pieces}
                index={index}
                insert={insert}
                appendPiece={appendPiece}
                defaultValue={operation.piece_id}
              />
            </Grid>
            <Grid item xs={4}>
              <DurationField
                operations={operations}
                index={index}
                defaultValue={operation.duration}
              />
            </Grid>
            <Grid item xs={4}>
              <HourlyRateField
                operations={operations}
                index={index}
                defaultValue={operation.hourly_rate}
              />
            </Grid>
            <Grid item xs={4}>
              <DiscountField
                index={index}
                defaultValue={operation.discount}
              />
            </Grid>
            <Grid item xs={6}>
              <TotalBeforeDiscountField index={index} defaultValue={operation.total_before_discount} />
            </Grid>
            <Grid item xs={6}>
              <TotalField index={index} defaultValue={operation.total} />
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() =>
            append({
              operation_id: '',
              piece_id: '',
              duration: 0,
              hourly_rate: 0,
              total: 0
            })
          }
        >
          Ajouter
        </Button>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end" gap={2}>
        <Grid item xs={4}>
          <SubTotalBeforeDiscountField
            defaultValue={methods.getValues('operations_sub_total_before_discount')}
          />
        </Grid>
        <Grid item xs={4}>
          <SubTotalField
            defaultValue={methods.getValues('operations_sub_total')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(InvoiceOperations);
