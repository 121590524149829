import {
  DataGrid,
  GridColDef,
  GridFilterModelParams,
  GridPageChangeParams,
  GridSortModelParams,
  GridToolbar
} from '@material-ui/data-grid';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { frFRGrid } from 'src/locales';
import { IPaginateQuery } from 'src/types/paginate';
import { muiToEloquent } from 'src/utils';

type Props = {
  queryKey: string[];
  columns: GridColDef[];
  paginator: (
    page: number,
    limit: number,
    query?: IPaginateQuery
  ) => Promise<any>;
  query?: IPaginateQuery;
};

const DataTable: React.FC<Props> = ({
  queryKey,
  columns,
  paginator,
  query = {}
}) => {
  const [pagination, setPagination] = useState({
    page: 0,
    total: 0,
    pageSize: 20
  });

  const [paginationQuery, setPaginationQuery] = useState(query);

  const { data, isLoading, isFetching, refetch } = useQuery(
    [...queryKey, pagination.page, pagination.pageSize, paginationQuery],
    () => paginator(pagination.page + 1, pagination.pageSize, paginationQuery),
    {
      onSuccess: (data) => {
        setPagination({
          ...pagination,
          total: +data.total
        });
      }
    }
  );

  useEffect(() => {
    if (data) {
      setPagination({
        page: --data.current_page,
        pageSize: data.perPage,
        total: data.total
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (param: GridPageChangeParams) => {
    setPagination({
      ...pagination,
      page: param.page
    });
  };
  const onPageSizeChange = (param: GridPageChangeParams) => {
    setPagination({
      ...pagination,
      page:
        pagination.page >= param.pageCount
          ? --param.pageCount
          : pagination.page,
      pageSize: param.pageSize
    });
  };

  useEffect(() => {
    refetch().catch(() => {});
  }, [pagination.page, pagination.pageSize, paginationQuery, refetch]);

  const handleSortChange = (newSort: GridSortModelParams) => {
    setPaginationQuery({
      ...paginationQuery,
      sort: [...newSort.sortModel, ...(query.sort || [])]
    });
  };

  const handleFilterChange = (newFilter: GridFilterModelParams) => {
    setPaginationQuery({
      ...paginationQuery,
      criteria: [
        ...muiToEloquent(newFilter.filterModel.items),
        ...(query.criteria || [])
      ]
    });
  };

  return (
    <DataGrid
      localeText={frFRGrid}
      rows={data?.data || []}
      columns={columns}
      pageSize={pagination.pageSize}
      rowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100, 200]}
      rowCount={pagination.total}
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      onSortModelChange={handleSortChange}
      onFilterModelChange={handleFilterChange}
      pagination
      page={pagination.page}
      loading={isLoading || isFetching}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      autoHeight
      disableSelectionOnClick
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
};

export default DataTable;
