import { Button, Grid } from '@material-ui/core';
import { memo } from 'react';
import { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { IInvoiceForm, IInvoicePiece } from 'src/types/invoice';
import { IOperation, OperationTypesEnum } from 'src/types/operation';
import { IPiece } from 'src/types/piece';
import { FormInput } from '../controls';
import FormSelectAutoComplete from '../controls/select-autocomplete';

const TotalBeforeDiscountField = ({ index }: { index: number }) => {
  const { watch, setValue } = useFormContext();
  const [quantity, unit_price] = watch([
    `pieces.${index}.quantity`,
    `pieces.${index}.unit_price`,
  ]);

  useEffect(() => {
    const total = (quantity || 0) * (unit_price || 0);
    setValue(`pieces.${index}.total_before_discount`, parseFloat(total.toFixed(3)));
  }, [quantity, unit_price, index, setValue]);

  return (
    <FormInput
      disabled
      name={`pieces.${index}.total_before_discount`}
      type="number"
      label="Prix HT Avant Remise"
    />
  );
};


const TotalField = ({ index }: { index: number }) => {
  const { watch, setValue } = useFormContext();
  const [quantity, unit_price, discount] = watch([
    `pieces.${index}.quantity`,
    `pieces.${index}.unit_price`,
    `pieces.${index}.discount`
  ]);

  useEffect(() => {
    const total = (quantity || 0) * (unit_price || 0) * (1 - discount / 100);
    setValue(`pieces.${index}.total`, parseFloat(total.toFixed(3)));
  }, [quantity, unit_price, index, setValue, discount]);

  return (
    <FormInput
      disabled
      name={`pieces.${index}.total`}
      type="number"
      label="Prix HT"
    />
  );
};

const SubTotalBeforeDiscountField = ({ defaultValue }: { defaultValue: number }) => {
  const { watch, setValue } = useFormContext();
  const pieces = watch(`pieces`);

  useEffect(() => {
    const total = (pieces as IInvoicePiece[]).reduce((result, piece) => {
      return result + piece.total_before_discount;
    }, 0);
    setValue(`pieces_sub_total_before_discount`, total);
  }, [pieces, setValue]);

  return (
    <FormInput
      disabled
      name={`pieces_sub_total_before_discount`}
      type="number"
      label="S. Total Pièces HT avant remise en TND"
      defaultValue={defaultValue}
    />
  );
};

const SubTotalField = ({ defaultValue }: { defaultValue: number }) => {
  const { watch, setValue } = useFormContext();
  const pieces = watch(`pieces`);

  useEffect(() => {
    const total = (pieces as IInvoicePiece[]).reduce((result, piece) => {
      return result + piece.total;
    }, 0);
    setValue(`pieces_sub_total`, total);
  }, [pieces, setValue]);

  return (
    <FormInput
      disabled
      name={`pieces_sub_total`}
      type="number"
      label="S. Total Pièces HT en TND"
      defaultValue={defaultValue}
    />
  );
};

const DiscountField = ({
  index,
  defaultValue
}: {
  index: number;
  defaultValue: number;
}) => {
  return (
    <FormInput
      name={`pieces.${index}.discount`}
      type="number"
      inputProps={{ min: 0, max: 100 }}
      onWheel={(e) => (e.target as HTMLElement).blur()}
      label="Remise"
      defaultValue={defaultValue}
    />
  );
};

type Props = {
  pieces: Array<IPiece>;
  operations: Array<IOperation>;
};

const InvoicePieces: React.FC<Props> = ({ pieces, operations }) => {
  const methods = useFormContext<IInvoiceForm>();
  const { fields, append, remove, update } = useFieldArray({
    control: methods.control,
    name: 'pieces'
  });

  const ioperations = useWatch({
    name: 'operations',
    control: methods.control
  });

  useEffect(() => {
    const paintingOperations = operations
      .filter((operation) => operation.type === OperationTypesEnum.PAINTING)
      .map((o) => o.id);
    const paintingIoperations = ioperations.filter((ioperation) =>
      paintingOperations.includes(ioperation.operation_id as number)
    );

    const paintingPieceIndex = fields.findIndex(
      (piece) => piece.piece_id === 1
    );
    if (paintingIoperations.length > 0) {
      if (paintingPieceIndex !== -1) {
        update(paintingPieceIndex, {
          ...fields[paintingPieceIndex],
          quantity: paintingIoperations.length
        });
      } else {
        append({
          piece_id: 1,
          reference: '',
          quantity: paintingIoperations.length,
          unit_price: 0,
          total: 0,
          discount: 0
        });
      }
    } else {
      if (paintingPieceIndex !== -1) {
        remove(paintingPieceIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ioperations]);

  return (
    <Grid container spacing={2}>
      {fields.map((piece, index) => (
        <Grid container item xs={12} key={piece.id} spacing={2}>
          <Grid item xs={1}>
            <Button variant="contained" onClick={() => remove(index)}>
              -
            </Button>
          </Grid>
          <Grid container item xs={11} spacing={3}>
            <Grid item xs={6}>
              <FormInput
                name={`pieces.${index}.reference`}
                label="Référence"
                defaultValue={piece.reference}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelectAutoComplete
                name={`pieces.${index}.piece_id`}
                label="Piéce"
                options={
                  pieces.map((piece) => {
                    return { label: piece.name, value: piece.id };
                  }) || []
                }
                defaultValue={piece.piece_id}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                name={`pieces.${index}.quantity`}
                label="Quantité"
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
                defaultValue={piece.quantity}
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                name={`pieces.${index}.unit_price`}
                label="Prix unitaire"
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
                defaultValue={piece.unit_price}
              />
            </Grid>
            <Grid item xs={4}>
              <DiscountField index={index} defaultValue={piece.discount} />
            </Grid>
            <Grid item xs={6}>
              <TotalBeforeDiscountField index={index} />
            </Grid>
            <Grid item xs={6}>
              <TotalField index={index} />
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() =>
            append({
              reference: '',
              piece_id: '',
              quantity: 1,
              unit_price: 0,
              total: 0
            })
          }
        >
          Ajouter
        </Button>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end" gap={2}>
        <Grid item xs={4}>
          <SubTotalBeforeDiscountField defaultValue={methods.getValues('pieces_sub_total_before_discount')} />
        </Grid>
        <Grid item xs={4}>
          <SubTotalField defaultValue={methods.getValues('pieces_sub_total')} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(InvoicePieces);
