import { useState } from 'react';
import { Box, Button, Step, StepLabel, Stepper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  DurationUnitEnum,
  IInvoice,
  IInvoiceForm,
  InvoiceType,
  PaymentModeEnum,
  PaymentStatusEnum
} from 'src/types/invoice';
import { Form } from '../controls';
import InvoiceAdditionalInformations from './additional-informations';
import InvoiceInformations from './informations';
import InvoiceOperations from './operations';
import InvoicePieces from './pieces';
import InvoiceSummary from './summary';
import { IOperation } from 'src/types/operation';
import { IPiece } from 'src/types/piece';
import { useQuery } from 'react-query';
import { OperationsApi, PiecesApi } from 'src/api';
import moment from 'moment';
import { stepperValidationSchema } from './validate';
import { yupResolver } from '@hookform/resolvers/yup';

function getSteps() {
  return [
    'Informations du client',
    "Main d'oeuvre",
    'Fourniture & Pièces de Rechange',
    'Informations supplémentaire',
    'Récapitulatif'
  ];
}

function getStepContent(
  stepIndex: number,
  operations: Array<IOperation>,
  pieces: Array<IPiece>,
  type: InvoiceType
) {
  switch (stepIndex) {
    case 0:
      return <InvoiceInformations />;
    case 1:
      return <InvoiceOperations operations={operations} pieces={pieces} />;
    case 2:
      return <InvoicePieces pieces={pieces} operations={operations} />;
    case 3:
      return <InvoiceAdditionalInformations type={type} />;
    case 4:
      return <InvoiceSummary type={type} />;
    default:
      return 'Unknown stepIndex';
  }
}

type Props = {
  defaultValues?: IInvoice;
  onSubmit: (data: Partial<IInvoiceForm>) => void;
  type: InvoiceType;
  mode: 'CREATE' | 'EDIT' | 'INVOICE_FROM_QUOTE' | 'DUPLICATE';
};

const DEFAULT_VALUES = {
  client_id: null,
  client_car_id: null,
  partner_id: null,
  responsible_id: null,
  payment_mode: PaymentModeEnum.CASH,
  payment_status: PaymentStatusEnum.PAID,
  comments: '',
  stamp_price: 0,
  tva: 0,
  hourly_rate: 0,
  working_duration: 1,
  working_duration_unit: DurationUnitEnum.minutes,
  date: moment().format('yyyy-MM-DD'),
  operations: [],
  pieces: [],
  operations_sub_total_before_discount: 0,
  operations_sub_total: 0,
  pieces_sub_total_before_discount: 0,
  pieces_sub_total: 0
};

const InvoiceForm: React.FC<Props> = ({
  defaultValues,
  onSubmit,
  type,
  mode
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const { data: operations } = useQuery(['invoice_operations'], () =>
    OperationsApi.paginate(1, -1)
  );
  const { data: pieces } = useQuery(['invoice_pieces'], () =>
    PiecesApi.paginate(1, -1)
  );

  const currentStepperValidationSchema =
    stepperValidationSchema(type)[activeStep];
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      ...((defaultValues as IInvoiceForm) || DEFAULT_VALUES),
      ...(['CREATE', 'INVOICE_FROM_QUOTE', 'DUPLICATE'].includes(mode)
        ? { date: moment().format('yyyy-MM-DD') }
        : {})
    },
    resolver: yupResolver(currentStepperValidationSchema),
    mode: 'onChange'
  });

  const handleNext = async () => {
    const isStepValid = await methods.trigger();
    isStepValid && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleLastStep = () => {
    if (activeStep === steps.length - 1) {
      onSubmit(methods.getValues());
    }
  };

  return (
    <Form submitHandler={() => {}} methods={methods}>
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          <Box>
            <Box py={4}>
              {getStepContent(
                activeStep,
                operations?.data || [],
                pieces?.data || [],
                type
              )}
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="contained"
                color="secondary"
                disabled={activeStep === 0}
                onClick={handleBack}
                type="button"
              >
                Retour
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLastStep}
                  type="button"
                >
                  Terminer
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  type="button"
                >
                  Suivant
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Form>
  );
};

export default InvoiceForm;
