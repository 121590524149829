import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    paddingLeft: 15,
    paddingRight: 15
  },
  footer: {
    borderTop: '2pt solid #F5781A',
    paddingTop: 5
  },
  footerText: {
    color: '#F5781A',
    fontSize: 8,
    textAlign: 'center'
  }
});
const InvoiceFooter: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text>Nous Vous remercions de votre confiance.</Text>
      <View style={styles.footer}>
        <Text style={styles.footerText}>
          PITSTOP SUARL - MF : 1550323K/A/M 000 au Capital de 50.000 TND
          Adresse: Intersection rue Jammel et rue du météore BORJ LOUZIR{'\n'}
          RIB : 04 142 226 0052 27778 7 18 Attijari Bank Agence Borj Louzir
        </Text>
      </View>
    </View>
  );
};

export default InvoiceFooter;
